.projects .card {
    max-width: 300px;

    .card-img-top {
        max-height: 300px;
        overflow-y: scroll;
        cursor: ns-resize;
    }

    /* width */
    .card-img-top::-webkit-scrollbar {
        width: 5px;
    }


    /* Handle */
    .card-img-top::-webkit-scrollbar-thumb {
        background: $secondary; 
    }

    /* Handle on hover */
    .card-img-top::-webkit-scrollbar-thumb:hover {
        background: lighten($secondary, 20%); 
    }

}