  @font-face {
    font-family: 'Hack';
    src: url('/assets/fonts/hack-regular.woff2?sha=3114f1256') format('woff2'), url('/assets/fonts/hack-regular.woff?sha=3114f1256') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hack';
    src: url('/assets/fonts/hack-bold.woff2?sha=3114f1256') format('woff2'), url('/assets/fonts/hack-bold.woff?sha=3114f1256') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Hack';
    src: url('/assets/fonts/hack-italic.woff2?sha=3114f1256') format('woff2'), url('/assets/fonts/hack-italic.woff?sha=3114f1256') format('woff');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Hack';
    src: url('/assets/fonts/hack-bolditalic.woff2?sha=3114f1256') format('woff2'), url('/assets/fonts/hack-bolditalic.woff?sha=3114f1256') format('woff');
    font-weight: 700;
    font-style: italic;
  }