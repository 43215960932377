.skills-heading {
    font-size: 16px;
    opacity: 0.8;
    font-weight: bold;
    border-bottom-style: solid;
    width: fit-content;
}

.skill {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    
    display: inline-block;
    font-weight: bold;
    font-size: small;

    cursor: default;
}

.skill:hover {
    border-color: var(--primary);
    color: var(--primary);
}