
.section-heading {
    font-size: 16px;
    opacity: 0.8;
    font-weight: bold;
    border-bottom-style: solid;
}

.nav-link.text-light:hover,
.nav-link.text-light:focus {
    color: var(--info) !important;
}

.year-sign {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */

    writing-mode: sideways-rl;
    text-orientation: revert;

    font-size: 0.8rem;
    margin-right: 5px;
}